import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { Container, Row, Col } from "react-bootstrap"

const Header = () => {
  const { headerOrig } = useStaticQuery(
    graphql`
      query {
        headerOrig: file(relativePath: { eq: "paint-light.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 50
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(headerOrig)
  const headerBg = convertToBgImage(image)

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...headerBg}
      preserveStackingContext
    >
      <header>
        <Container className="header">
          <Row>
            <Col className="intro-text">
              <div class="d-none d-xl-block">
                <span className="intro-heading-script">Carla Young</span>
                &nbsp;&nbsp;&nbsp;
                <span className="intro-heading-trajan">
                  Fine Arts Appraisals
                </span>
              </div>
              <div class="d-xl-none">
                <span class="intro-heading-script">Carla Young</span>
                <br />
                <span class="intro-heading-trajan">Fine Arts Appraisals</span>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </BackgroundImage>
  )
}

export default Header

import React from "react"

import { Link } from "gatsby"
import Menu from "./menu"

const Navigation = () => (
  <nav>
    <div className="navigation-large d-none d-md-flex justify-content-between fixed-top">
      <div className="d-flex justify-content-between name">
        <Link to="/">Carla Young</Link>
      </div>
      <div className="d-flex justify-content-between section-nav">
        <Link to="/#specialties">Specialties</Link>
        <Link to="/#services">Services</Link>
        <Link to="/appraisals">Appraisals</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
    <div className="navigation-small d-flex justify-content-between d-md-none fixed-top">
      <div className="d-flex justify-content-between name">
        <Link to="/">Carla Young</Link>
      </div>
      <div>
        <Menu />
      </div>
    </div>
  </nav>
)

export default Navigation

import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="d-none d-md-flex">
    <div className="footer justify-content-between">
      <div className="d-flex justify-content-between">
        <Link to="/contact" className="nav-link">
          Contact
        </Link>
        <a
          href="https://www.linkedin.com/in/carla-young-90a66420"
          className="nav-link"
        >
          LinkedIn
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
